<template>
  <v-dialog :value="dialog" persistent max-width="400">
    <v-card>
      <v-card-title class="text-h5"> Create Prompt </v-card-title>

      <div class="px-5 my-5">
        <v-text-field
          label="Prompt Name"
          placeholder="Prompt Name"
          outlined
          v-model="promtName"
        ></v-text-field>
        <v-select
          outlined
          label="AI Model"
          :items="models"
          v-model="selectedModel"
          item-text="label"
          item-value="value"
          @change="setModel"
        ></v-select>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')"> Cancel </v-btn>
        <v-btn color="blue darken-1" text @click="addPrompt"> Create </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions: promtsActions } = createNamespacedHelpers('promts');

export default {
  name: 'CreatePromts',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      models: [
        {
          label: 'Claude V3 Sonnet',
          value: 'anthropic.claude-3-sonnet-20240229-v1:0'
        },
        {
          label: 'Claude 3.5 Sonnet',
          value: 'anthropic.claude-3-5-sonnet-20240620-v1:0'
        },
        {
          label: 'Claude 3.5 Sonnet v2',
          value: 'anthropic.claude-3-5-sonnet-20241022-v2:0'
        },
        {
          label: 'Claude V3 Haiku',
          value: 'anthropic.claude-3-haiku-20240307-v1:0'
        },
        {
          label: 'Claude V3.5 Haiku',
          value: 'anthropic.claude-3-5-haiku-20241022-v1:0'
        },
      ],
      selectedModel: {
        label: 'Claude V3 Haiku',
        value: 'anthropic.claude-3-haiku-20240307-v1:0'
      },
      promtName: '',
    };
  },
  methods: {
    ...promtsActions(['createPromt']),
    setModel(model) {
      this.selectedModel = model;
    },
    addPrompt() {
      this.createPromt({
        name: this.promtName,
        model: this.selectedModel.value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './create-promt';
</style>
